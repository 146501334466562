import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'
import React from 'react'

const CustomDialog = ({ open, onClose, event, text, btnText }) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{text}</DialogTitle>
        <DialogActions>
          <Button onClick={onClose}>Отмена</Button>
          <Button onClick={event} autoFocus>
            {btnText}
          </Button>
        </DialogActions>
      </Dialog>
    </>

  )
}

export default CustomDialog