import React, { useEffect, useRef, useState } from 'react'
import { Header } from '../../../../components/Header/Header'
import PinDropRoundedIcon from '@mui/icons-material/PinDropRounded';
import { couriersRef } from './../../../../utils/collectionRefs';
import { collection, getDocs, limit, query, orderBy } from 'firebase/firestore';
import { Title } from '../../../../components/Title/Title';
import { GoogleMap, InfoWindow, MarkerF, Polyline, useLoadScript } from '@react-google-maps/api';
import { Button, MenuItem, Rating, Stack, TextField, Tooltip } from '@mui/material';
import './scss/CourierDetail.scss'
import { Helmet } from 'react-helmet';
import { useForm, Controller } from 'react-hook-form';
import { db } from '../../../../configs';
import FilterAltOffRoundedIcon from '@mui/icons-material/FilterAltOffRounded';


const CourierMap = ({ pageTitle }) => {
  const [couriers, setCouriers] = useState(null)
  const [courierLocations, setCourierLocations] = useState([]);
  const [showCouriers, setShowCouriers] = useState(true);
  const [mapCenter, setMapCenter] = useState({ lat: 42.882004, lng: 74.582748 });
  const [visibleInfo, setVisibleInfo] = useState(null);
  const mapRef = useRef(null);

  const { isLoaded } = useLoadScript({ googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY })
  const { control, handleSubmit, reset, watch } = useForm({
    defaultValues: { courier: '', date: '' },
  });

  const userName = localStorage.getItem('userName');
  const roleType = localStorage.getItem('roleType');
  const isAdminNuriko = roleType === 'admin' && userName === 'nuriko'

  const watchFields = watch(['courier', 'date']);

  const onMapLoad = (map) => {
    mapRef.current = map;
  };

  useEffect(() => {
    fetchCouriers();
  }, []);

  useEffect(() => {
    if (watchFields.courier && watchFields.date) {
      fetchLocations(watchFields.courier, watchFields.date);
    }
  }, [watchFields.courier, watchFields.date]);

  const fetchCouriers = async () => {
    try {
      const docSnapshot = await getDocs(couriersRef);
      const courierData = docSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setCouriers(courierData);
    } catch (error) {
      console.error('Error fetching couriers:', error);
    }
  };

  const fetchCourierLocations = async (courierId, selectedDate) => {
    const locationRef = query(
      collection(db, 'couriers', courierId, 'locations', selectedDate, 'bydate'),
      orderBy('date', 'desc'),
      limit(1000),
    );
    const snapshot = await getDocs(locationRef);
    const locations = snapshot.docs.map(doc => doc.data());
    const uniqueLocations = locations.filter((location, index, self) =>
      index === self.findIndex((l) => (
        l.lat === location.lat && l.lon === location.lon
      ))
    );
    return uniqueLocations;
  };

  const fetchLocations = async (courierId, selectedDate) => {
    const locationRef = query(
      collection(db, 'couriers', courierId, 'locations', selectedDate, 'bydate'),
      orderBy('date', 'desc'),
      limit(1000),
    );

    const snapshot = await getDocs(locationRef);
    const locations = snapshot.docs.map(doc => doc.data());
    const uniqueLocations = locations.filter((location, index, self) =>
      index === self.findIndex((l) => l.lat === location.lat && l.lon === location.lon)
    );

    setCourierLocations(uniqueLocations);
    if (uniqueLocations.length > 0) {
      setMapCenter({ lat: Number(uniqueLocations[0].lat), lng: Number(uniqueLocations[0].lon) });
    }
  };

  const onSubmit = async (data) => {
    const locations = await fetchCourierLocations(data.courier, data.date);
    setShowCouriers(false)
    setCourierLocations(locations);
  };

  const handleReset = () => {
    reset();
  };
  const handleVisibleInfo = (courierId) => {
    setVisibleInfo((prev) => (prev === courierId ? null : courierId));
  };

  const handleShowCouriers = () => {
    setShowCouriers(true)
    setCourierLocations([])
    reset();
  };

  const renderMarkers = () => (
    <>
      {couriers?.map(({ id, lat, lon, name, surName, phone, type, raiting }) => (
        <MarkerF
          key={id}
          position={{ lat: Number(lat), lng: Number(lon) }}
          onClick={() => handleVisibleInfo(id)}
        >
          {visibleInfo === id && (
            <InfoWindow onCloseClick={() => setVisibleInfo(null)}>
              <div>
                <h3>{`${name} ${surName}`}</h3>
                <p>Телефон: {phone}</p>
                <p>Тип курьера: {type}</p>
                <Rating defaultValue={parseFloat(raiting)} precision={0.5} size="small" readOnly />
              </div>
            </InfoWindow>
          )}
        </MarkerF>
      ))}
    </>
  );

  const renderPolylineAndMarkers = () => {
    const latA = Number(courierLocations[0].lat)
    const lngA = Number(courierLocations[0].lon)
    const latB = Number(courierLocations[courierLocations.length - 1].lat)
    const lngB = Number(courierLocations[courierLocations.length - 1].lon)
    const polyLineOptions = { strokeColor: '#FF0000', strokeOpacity: 0.8, strokeWeight: 2 }
    return (
      <>
        <MarkerF
          position={{ lat: latA, lng: lngA }}
          label="A"
          icon={{
            path: window?.google?.maps.SymbolPath.CIRCLE,
            scale: 20,
            fillColor: '#10B981',
            fillOpacity: 1,
            strokeWeight: 2,
            strokeColor: 'black'
          }}
        />
        {courierLocations.map(({ lat, lon, date }) => (
          <MarkerF key={date.seconds} position={{ lat: Number(lat), lng: Number(lon) }} />
        ))}
        {courierLocations.length > 1 && (
          <Polyline
            path={courierLocations.map(({ lat, lon }) => ({ lat: Number(lat), lng: Number(lon) }))}
            options={polyLineOptions}
          />
        )}
        <MarkerF
          position={{ lat: latB, lng: lngB }}
          label="B"
          icon={{
            path: window?.google?.maps.SymbolPath.CIRCLE,
            scale: 20,
            fillColor: 'gold',
            fillOpacity: 1,
            strokeWeight: 2,
            strokeColor: 'gray',
          }}
        />
      </>
    )
  }

  return (
    <>
      <div className="containerr">
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Header previous='Статистика' initial={'Карта курьеров'} />
        <Title title='Карта курьеров' icon={<PinDropRoundedIcon fontSize='large' />} />
        <div className="container-inner">
          {isAdminNuriko && (
            <form className="search-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="search-input-block">
                <Stack sx={{ width: '100%' }} direction='row' gap={2}>
                  <Controller
                    name='courier'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        label='По курьеру'
                        size='small'
                        fullWidth
                      >
                        {couriers?.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                  <Controller
                    name='date'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="Дата"
                        type="date"
                        fullWidth
                        size='small'
                        value={field.value || ''}
                        InputLabelProps={{ shrink: true }} // Ensures the label is always visible
                        {...field}
                      />
                    )}
                  />

                </Stack>
                {!showCouriers ?
                  <div className="order-sort">
                    <Button variant='black-contained' onClick={handleShowCouriers}>
                      Показать курьеров
                    </Button>
                  </div>
                  : null}
                <Stack direction='row' gap={1}>
                  <Button type='submit' variant='black-contained' fullWidth>
                    Применить
                  </Button>
                  <Tooltip title="Сбросить фильтры" placement='right' arrow>
                    <Button
                      variant='contained'
                      onClick={handleReset}
                      sx={{ width: '10%' }}
                      color='inherit'
                    >
                      <FilterAltOffRoundedIcon />
                    </Button>
                  </Tooltip>
                </Stack>
              </div>
            </form>
          )}
          <div className='map-container'>
            <div className="map-inner">
              {isLoaded && (
                <GoogleMap
                  center={mapCenter}
                  zoom={12}
                  onClick={() => setVisibleInfo(null)}
                  mapContainerStyle={{
                    height: '100%',
                    width: '100%',
                    borderRadius: '12px'
                  }}
                  options={{
                    mapTypeControl: false,
                    zoomControl: false,
                  }}
                  onLoad={onMapLoad}
                >
                  {showCouriers ? renderMarkers() : renderPolylineAndMarkers()}
                </GoogleMap>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CourierMap