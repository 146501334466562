import React, { useEffect, useState } from 'react';
import { db } from '../../../../configs';
import { Title } from '../../../../components/Title/Title';
import { Loader } from '../../../../components/Loader/Loader';
import { Header } from '../../../../components/Header/Header';
import { Controller, useForm } from 'react-hook-form';
import { citiesRef, userCollectionRef } from '../../../../utils/collectionRefs';
import { courierType } from '../../../../utils';
import { useNavigate } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { doc, setDoc, onSnapshot, addDoc } from 'firebase/firestore';
import './scss/AddCourier.scss';
import {
  MenuItem,
  TextField,
  Button,
  Box,
  Backdrop,
  CircularProgress,
  FormControl,
  Rating,
  Typography,
  Autocomplete,
} from '@mui/material';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

const AddCourier = ({pageTitle}) => {
  const [city, setCity] = useState(null);
  const [open, setOpen] = useState(false);
  const userType = localStorage.getItem('userType');
  const userName = localStorage.getItem('userName');
  const navigate = useNavigate();
  const date = new Date()

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  const pinValidationRules = {
    required: 'Обязательное полe!',
    validate: (value) => {
      if (value.length < 6) {
        return 'ПИН должен быть больше 5 символов';
      }
      if (value.length > 6) {
        return 'ПИН не должен быть больше 6 символов';
      }
    },
  };

  const handleAddCourirer = async (data) => {
    setOpen(true);
    try {
      await setDoc(doc(db, 'couriers', `${data.phone}`), {
        ...data,
        city: String(data.cityInfo.id),
        active: true,
        online: false,
        balance: 0,
      });
      await addDoc(userCollectionRef, {
        date: date,
        description: `${userType} ${userName} добавил агента ${data.name} ${data.surName}`
      })
      setOpen(false);
      navigate('/couriers');

      toast.success('Курьер успешно добавлен', {
        autoClose: 2000,
        hideProgressBar: false,
      });
    } catch (e) {
      setOpen(false);
      toast.error('Произошла ошибка при добавлении курьера', {
        autoClose: 2000,
        hideProgressBar: false,
      });
    } finally {
      reset();
    }
  };

  useEffect(() => {
    const settingCity = onSnapshot(citiesRef, (snapshot) => {
      const cityData = snapshot.docs.map((doc) => ({ ...doc.data() }));
      const sortedCityData = cityData.sort((a, b) => a.id - b.id); // Sort by id
      setCity(sortedCityData);
    });
    return () => settingCity();
  }, []);

  return (
    <>
      <div className='container'>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
        <Header previous='Статистика' initial='Добавить курьера' />
        <Title title={'Добавление курьера'} icon={<AddCircleIcon fontSize='24' />} />
        <div className='container-inner'>
          {!city ? (
            <Loader />
          ) : (
            <div className='addCourier-wrapper'>
              <div className='order-block-head'>
                <h3>добавление курьера</h3>
              </div>
              <form className='add-courier-form'>
                <Box className='add-courier-form__input'>
                  <Controller
                    name='name'
                    control={control}
                    defaultValue=''
                    rules={{ required: 'Обьязательное полe!' }}
                    render={({ field }) => (
                      <TextField
                        label='Имя'
                        placeholder='Иван'
                        multiline
                        variant='outlined'
                        size='small'
                        helperText={errors?.name?.message}
                        error={errors?.name && true}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name='surName'
                    control={control}
                    defaultValue=''
                    rules={{ required: 'Обьязательное полe!' }}
                    render={({ field }) => (
                      <TextField
                        label='Фамилия'
                        placeholder='Иванов'
                        variant='outlined'
                        size='small'
                        helperText={errors?.surName?.message}
                        error={errors?.surName && true}
                        {...field}
                      />
                    )}
                  />
                </Box>
                <Box className='add-courier-form__input'>
                  <Controller
                    name='pin'
                    control={control}
                    defaultValue=''
                    rules={pinValidationRules}
                    render={({ field }) => (
                      <TextField
                        label='ПИН'
                        placeholder='010101'
                        variant='outlined'
                        sx={{ flex: 1 }}
                        size='small'
                        type='text'
                        inputProps={{ inputMode: 'numeric' }}
                        helperText={errors?.pin?.message}
                        error={errors?.pin && true}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name='phone'
                    control={control}
                    defaultValue={996}
                    rules={{
                      required: 'Обьязательное полe!',
                      pattern: {
                        value: /^996\d{3}\d{2}\d{2}\d{2}$/,
                        message: 'Неверный формат номера',
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        label='Моб-номер'
                        placeholder='(996)700777777'
                        variant='outlined'
                        size='small'
                        sx={{ flex: 1 }}
                        type='number'
                        helperText={errors?.phone?.message}
                        error={errors?.phone && true}
                        {...field}
                      />
                    )}
                  />
                </Box>
                <Box className='add-courier-form__input'>
                  <Controller
                    name='type'
                    control={control}
                    defaultValue={courierType[0].title}
                    rules={{ required: 'Обязательное поле!' }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id='filled-select-currency'
                        select
                        label='Тип курьера'
                        variant='outlined'
                        size='small'
                        error={!!errors.type}
                        helperText={errors.type ? errors.type.message : ''}
                      >
                        {courierType?.map((type) => (
                          <MenuItem key={type.id} value={type.title}>
                            {type.title}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                  <Controller
                    name='cityInfo'
                    control={control}
                    defaultValue={city[0]}
                    rules={{ required: 'Обязательное поле!' }}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        disablePortal
                        options={city || []}
                        getOptionLabel={(option) => option?.name || ''}
                        onChange={(_, val) => { field.onChange(val) }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label='Город/район'
                            variant='outlined'
                            size='small'
                            style={{ width: '100%' }}
                            error={!!errors.cityInfo}
                            helperText={errors?.cityInfo?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Box>
                <FormControl fullWidth>
                  <Typography variant='body2' color='disabled'>
                    Рейтинг
                  </Typography>
                  <Controller
                    name='raiting'
                    control={control}
                    defaultValue={0}
                    rules={{
                      required: 'Обьязательное полe!',
                    }}
                    render={({ field }) => (
                      <Rating
                        name='rating'
                        style={{ width: '125px' }}
                        value={field.value}
                        precision={0.5}
                        onChange={(event, newValue) => field.onChange(newValue)}
                      />
                    )}
                  />
                </FormControl>
                <Button
                  onClick={handleSubmit((data) => handleAddCourirer(data))}
                  size='large'
                  variant='contained'
                  style={{ background: '#000b1f' }}
                  sx={{ marginTop: '10px' }}
                >
                  Создать курьера
                </Button>
              </form>
            </div>
          )}
        </div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      </div>
    </>
  );
};

export default AddCourier;
