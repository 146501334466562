import React from 'react';
import { GrBike } from 'react-icons/gr';
import { FaWalking } from 'react-icons/fa';
import { MdDeliveryDining } from 'react-icons/md';
import { TbTruckDelivery } from 'react-icons/tb';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import AssignmentTurnedInRoundedIcon from '@mui/icons-material/AssignmentTurnedInRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';
import MoneyOffCsredRoundedIcon from '@mui/icons-material/MoneyOffCsredRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import AssignmentLateRoundedIcon from '@mui/icons-material/AssignmentLateRounded';
import AssignmentReturnRoundedIcon from '@mui/icons-material/AssignmentReturnRounded';
import UpdateRoundedIcon from '@mui/icons-material/UpdateRounded';
import AutoDeleteRoundedIcon from '@mui/icons-material/AutoDeleteRounded';
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import FindInPageRoundedIcon from '@mui/icons-material/FindInPageRounded';
import StoreMallDirectoryRoundedIcon from '@mui/icons-material/StoreMallDirectoryRounded';
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import StorefrontRoundedIcon from '@mui/icons-material/StorefrontRounded';
import RecentActorsRoundedIcon from '@mui/icons-material/RecentActorsRounded';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import RequestQuoteRoundedIcon from '@mui/icons-material/RequestQuoteRounded';

export const sideListForAdmin = [
  {
    icon: <AccountBoxRoundedIcon />,
    title: 'Профиль',
    path: '/',
  },
  {
    icon: <AssessmentIcon />,
    title: 'Статистика',
    path: '/dashboard',
  },
  {
    icon: <FindInPageRoundedIcon />,
    title: 'Искать везде',
    path: '/search',
  },
  {
    icon: <AddCircleIcon />,
    title: 'Создать заказ',
    path: '/order/add',
  },
  {
    icon: <AssignmentRoundedIcon />,
    title: 'Активные заказы',
    path: '/orders',
  },
  {
    icon: <AssignmentRoundedIcon />,
    title: 'Активные заказы 2',
    path: '/orderss',
  },
  {
    icon: <AssignmentTurnedInRoundedIcon />,
    title: 'Завершеные заказы',
    path: '/order/completed',
  },
  {
    icon: <AssignmentReturnRoundedIcon />,
    title: 'Возвраты',
    path: '/returned-orders',
  },
  {
    icon: <AssignmentLateRoundedIcon />,
    title: 'Отмененные заказы',
    path: '/order/cancelled',
  },
  {
    icon: <AutoDeleteRoundedIcon />,
    title: 'Удаленные заказы',
    path: '/order/deleted',
  },
  {
    icon: <UpdateRoundedIcon />,
    title: 'Долгие заказы',
    path: '/longorders',
  },
  {
    icon: <RequestQuoteRoundedIcon />,
    title: 'Счет фактура',
    path: '/schetFaktura',
  },
  {
    icon: <MoneyOffCsredRoundedIcon />,
    title: 'Долги',
    path: '/order/debt',
  },
  {
    icon: <PersonAddAltRoundedIcon />,
    title: 'Создать курьера',
    path: '/courier/add',
  },
  {
    icon: <RecentActorsRoundedIcon />,
    title: 'Список курьеров',
    path: '/couriers',
  },
  {
    icon: <LocationOnRoundedIcon />,
    title: 'Карта курьеров',
    path: '/courier/map',
  },
  {
    icon: <PeopleAltRoundedIcon />,
    title: 'Список клиентов',
    path: '/clients',
  },
  {
    icon: <AddCircleIcon />,
    title: 'Добавить агента',
    path: '/agent/add',
  },

  {
    icon: <PeopleAltRoundedIcon />,
    title: 'Список агентов',
    path: '/agents',
  },
  {
    icon: <AddBusinessRoundedIcon />,
    title: 'Добавить магазин',
    path: '/store/add',
  },
  {
    icon: <StoreMallDirectoryRoundedIcon />,
    title: 'Магазины',
    path: '/stores',
  },
  {
    icon: <AddBusinessRoundedIcon />,
    title: 'Добавить категорию',
    path: '/storeCategory/add',

  },
  {
    icon: <StorefrontRoundedIcon />,
    title: 'Категории магазинов',
    path: '/storeCategories',
  },
  {
    icon: <MonetizationOnRoundedIcon />,
    title: 'Транзакции',
    path: '/transaction',
  },
  {
    icon: <AddCircleIcon />,
    title: 'Создать заказ (Город)',
    path: '/cityOrder/add',
  },
  {
    icon: <PublicRoundedIcon />,
    title: 'Список заказов (Город)',
    path: '/cityOrders',
  },
  {
    icon: <PublicRoundedIcon />,
    title: 'Города | Регионы',
    path: '/cities',
  },

  // {
  //   icon: <AddCircleIcon />,
  //   title: 'Добавить город',
  //   path: '/city/add',
  // },
  // {
  //   icon: <AddCircleIcon />,
  //   title: 'Добавить регион/село',
  //   path: '/village/add',
  // },
];

export const sideListForManager = [
  {
    icon: <AccountBoxRoundedIcon />,
    title: 'Профиль',
    path: '/',
  },
  {
    icon: <AssessmentIcon />,
    title: 'Статистика',
    path: '/dashboard',
  },
  {
    icon: <FindInPageRoundedIcon />,
    title: 'Искать везде',
    path: '/search',
  },
  {
    icon: <AddCircleIcon />,
    title: 'Создать заказ',
    path: '/order/add',
  },
  {
    icon: <AssignmentRoundedIcon />,
    title: 'Список заказов',
    path: '/orders',
  },
  {
    icon: <AssignmentRoundedIcon />,
    title: 'Список заказов 2',
    path: '/orderss',
  },
  {
    icon: <AssignmentTurnedInRoundedIcon />,
    title: 'Завершеные заказы',
    path: '/order/completed',
  },
  {
    icon: <AssignmentReturnRoundedIcon />,
    title: 'Возвраты',
    path: '/returned-orders',
  },
  {
    icon: <AssignmentLateRoundedIcon />,
    title: 'Отмененные заказы',
    path: '/order/cancelled',
  },
  {
    icon: <AutoDeleteRoundedIcon />,
    title: 'Удаленные заказы',
    path: '/order/deleted',
  },
  {
    icon: <UpdateRoundedIcon />,
    title: 'Долгие заказы',
    path: '/longorders',
  },
  {
    icon: <RequestQuoteRoundedIcon />,
    title: 'Счет фактура',
    path: '/schetFaktura',
  },
  {
    icon: <MoneyOffCsredRoundedIcon />,
    title: 'Долги',
    path: '/order/debt',
  },
  {
    icon: <LocationOnRoundedIcon />,
    title: 'Карта курьеров',
    path: '/courier/map',
  },
  {
    icon: <PeopleAltRoundedIcon />,
    title: 'Список клиентов',
    path: '/clients',
  },
  {
    icon: <AddCircleIcon />,
    title: 'Добавить агента',
    path: '/agent/add',
  },

  {
    icon: <PeopleAltRoundedIcon />,
    title: 'Список агентов',
    path: '/agents',
  },
  {
    icon: <AddBusinessRoundedIcon />,
    title: 'Добавить магазин',
    path: '/store/add',
  },
  {
    icon: <StoreMallDirectoryRoundedIcon />,
    title: 'Магазины',
    path: '/stores',
  },
  {
    icon: <AddBusinessRoundedIcon />,
    title: 'Добавить категорию',
    path: '/storeCategory/add',
  },
  {
    icon: <StorefrontRoundedIcon />,
    title: 'Категории магазинов',
    path: '/storeCategories',
  },
  {
    icon: <MonetizationOnRoundedIcon />,
    title: 'Транзакции',
    path: '/transaction',
  },
  {
    icon: <AddCircleIcon />,
    title: 'Создать заказ (Город)',
    path: '/cityOrder/add',
  },
  {
    icon: <PublicRoundedIcon />,
    title: 'Список заказов (Город)',
    path: '/cityOrders',
  },
  {
    icon: <PublicRoundedIcon />,
    title: 'Города | Регионы',
    path: '/cities',
  },

  // {
  //   icon: <AddCircleIcon />,
  //   title: 'Добавить город',
  //   path: '/city/add',
  // },
  // {
  //   icon: <AddCircleIcon />,
  //   title: 'Добавить регион/село',
  //   path: '/village/add',
  // },
];

// export const sideList2 = [

// ];

export const courierType = [
  { id: 1, icon: <FaWalking />, title: 'Пеший' },
  { id: 2, icon: <GrBike />, title: 'На велике' },
  { id: 3, icon: <MdDeliveryDining />, title: 'На мопеде' },
  { id: 4, icon: <TbTruckDelivery />, title: 'На машине' },
];

// ---------------------------------------------------------------

// ORDER-UNPUT==================================
export const typeOfOrder = [
  { id: 1, value: 'document', name: 'Документ' },
  { id: 2, value: 'medicine', name: 'Лекарство' },
  { id: 3, value: 'food', name: 'Еда' },
  { id: 4, value: 'small_box', name: 'Маленькая коробка' },
  { id: 5, value: 'box', name: 'Коробка' },
  { id: 6, value: 'large_box', name: 'Большая коробка' },
  { id: 7, value: 'Пакет', name: 'Пакет' },
  { id: 8, value: 'other', name: 'Другое' },
];
export const orderTariff = [
  { id: 1, value: 'По городу', title: 'По городу (150С̲)' },
  { id: 2, value: 'Жилмассив', title: 'Жилмассив (220С̲)' },
  { id: 3, value: 'От двери до двери', title: 'От двери до двери (180С̲)' },
  { id: 4, value: 'Регионы', title: 'Регионы (300С̲)' },
];
export const payment = [
  { id: 1, value: 'cash', title: 'Наличными' },
  { id: 2, value: 'mbank', title: 'МБАНК' },
  { id: 3, value: 'optima', title: 'Оптима' },
  { id: 5, value: 'elsom', title: 'Элсом' },
  { id: 4, value: 'odengi', title: 'О!-деньги' },
  { id: 5, value: 'other', title: 'Другое' },
];
export const paymentMethodd = [
  { id: 1, value: 'cash', name: 'Наличными' },
  { id: 2, value: 'mbank', name: 'МБАНК' },
  { id: 3, value: 'optima', name: 'Оптима' },
  { id: 5, value: 'elsom', name: 'Элсом' },
  { id: 6, value: 'odengi', name: 'О!-деньги' },
  { id: 7, value: 'schet_faktura', name: 'Счет фактура' },
  { id: 8, value: 'other', name: 'Другое' },
];
export const orderDebtLabel = [
  { id: 1, value: true, name: 'Долги' },
];

export const paymentStatus = [
  { id: 1, value: 'false', title: 'Не оплачен' },
  { id: 2, value: 'true', title: 'Оплачен' },
];
export const paymentPerson = [
  { id: 1, value: 1, name: 'Отправитель' },
  { id: 2, value: 2, name: 'Получатель' },
  { id: 3, value: 3, name: 'Совместно' },
];
export const debtPaidStatus = [
  { id: 1, value: true, name: 'Оплаченные' },
  { id: 2, value: false, name: 'Не оплаченные' },
];

export const paymentMethods = {
  cash: 'Наличными',
  mbank: 'МБАНК',
  optima: 'Оптима',
  odengi: 'О!Деньги',
  elsom: 'Элсом',
  schet_faktura: 'Счет фактура',
  other: 'Другое',
};
// ORDER-UNPUT==================================

// ---------------------------------------------------------------

// COURIERS-AND-ORDER-TABLE=====================
export const courierTableList = [
  { field: 'id', headerName: '#ID', width: 70 },
  { field: 'name', headerName: 'Имя', width: 130 },
  { field: 'surName', headerName: 'Фамилия', width: 130 },
  { field: 'type', headerName: 'Тип', width: 130, sortable: false },
  { field: 'city', headerName: 'Город', width: 130, sortable: false },
  { field: 'raiting', headerName: 'Рейтинг', width: 130 },
  {
    field: 'online',
    headerName: 'Статус',
    width: 130,
    sortable: false,
    valueGetter: (params) =>
      `${params.row.online === false ? 'Оффлайн' : `Онлайн`}`,
  },
  {
    field: 'balance',
    headerName: 'Баланс',
    width: 130,
    sortable: false,
    valueGetter: (params) =>
      `${!params.row.balance ? '0' : params.row.balance}`,
  },
  // {
  //   field: 'number', headerName: 'Моб-номер', sortable: false, width: 160,
  // },
];
// ORDER-TABLE=====================

export const orderLabels = [
  { name: 'Дата создания' },
  { name: 'Номер заказа' },
  { name: 'Курьер' },
  { name: 'Курьер 2' },
  { name: 'Статус' },
  { name: 'Тип заказа' },
  { name: 'Откуда' },
  { name: 'Отправитель' },
  { name: 'Куда' },
  { name: 'Получатель' },
  { name: 'Цена' },
  { name: 'Выкуп' },
  { name: 'Кто оплачивает' },
  { name: 'Метод оплаты' },
  { name: 'SenderPays' },
  { name: 'Действия' },
];

export const columnCount = [
  { id: 1, name: 20 },
  { id: 2, name: 50 },
  { id: 3, name: 100 },
];
// PACKAGELABELS
export const packageTypeLabels = {
  document: 'Документ',
  medicine: 'Лекарство',
  large_box: 'L коробка',
  small_box: 'S коробка',
  box: 'M коробка',
  food: 'Еда',
  paket: 'Пакет',
  Пакет: 'Пакет',
  other: 'Другое',
};
// ---------------------------------------------------------------

// SORTING-ORDER-LIST

// SORT-BY-COST

export const sortByCostList = [
  { id: 1, value: 150, name: '150С̲' },
  { id: 2, value: 180, name: '180С̲' },
  { id: 3, value: 220, name: '220С̲' },
  { id: 4, value: 300, name: '300С̲' },
];

// ORDER-STATUS

export const orderStatus = [
  { name: 'Новый', value: 'status_new' },
  { name: 'Подтвержден', value: 'status_confirmed' },
  { name: 'Прибыл к отправителю', value: 'status_arrived_sender' },
  { name: 'У курьера', value: 'status_on_courier' },
  { name: 'B сорт.центре', value: 'status_at_sorting_center' },
  { name: 'В пути', value: 'status_on_way_cc' },
  { name: 'В сорт.центре (2)', value: 'status_at_sorting_center2' },
  { name: 'У курьера (2)', value: 'status_on_courier2' },
  { name: 'Прибыл к получателю', value: 'status_arrived_receiver' },
  { name: 'Доставлен', value: 'status_delivered' },
  { name: 'Отменен', value: 'status_cancelled' },
  { name: 'Отклонен', value: 'status_rejected' },

];

export const orderStatus2 = [
  { name: 'Новый', value: 'status_new' },
  { name: 'Подтвержден', value: 'status_confirmed' },
  { name: 'Прибыл к отправителю', value: 'status_arrived_sender' },
  { name: 'У курьера', value: 'status_on_courier' },
  { name: 'B сорт.центре (город отправителя)', value: 'status_at_sorting_center' },
  { name: 'В пути', value: 'status_on_way_cc' },
  { name: 'В сорт.центре (город получателя)', value: 'status_at_sorting_center2' },
  { name: 'У курьера (2)', value: 'status_on_courier2' },
  { name: 'Прибыл к получателю', value: 'status_arrived_receiver' },
  { name: 'Доставлен', value: 'status_delivered' },
  { name: 'Отменен', value: 'status_cancelled' },
  { name: 'Отклонен', value: 'status_rejected' },
];

export const statusLabels = {
  status_new: 'Новый',
  status_confirmed: 'Подтвержден',
  status_arrived_sender: 'Прибыл к отправ',
  status_on_courier: 'У курьера',
  status_at_sorting_center: 'B сорт.центре',
  status_on_way_cc: 'В пути',
  status_at_sorting_center2: 'В сорт.центре (2)',
  status_on_courier2: 'У курьера (2)',
  status_arrived_receiver: 'Прибыл к получ',
  status_delivered: 'Доставлен',
  status_cancelled: 'Отменен',
  status_rejected: 'Отклонен',
};

export const alternativeStatusLabels = {
  Новый: 'status_new',
  Подтвержден: 'status_confirmed',
  'Прибыл к отправителю': 'status_arrived_sender',
  'У курьера': 'status_on_courier',
  'В сорт.центре': 'status_at_sorting_center',
  'В пути': 'status_on_way_cc',
  'В сорт.центре (2)': 'status_at_sorting_center2',
  'У курьера (2)': 'status_on_courier2',
  'Прибыл к получателю': 'status_arrived_receiver',
  Доставлен: 'status_delivered',
  Отклонен: 'status_rejected',
  Отменен: 'status_cancelled',
};

export const autoLinkDelimiter = /((?:https?:\/\/)?(?:(?:[a-z0-9]?(?:[a-z0-9-]{1,61}[a-z0-9])?\.[^.\s])+[a-z.]*[a-z]+|(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3})(?::\d{1,5})*[a-z0-9.,_/~#&=;%+?\-()]*)/gi;
