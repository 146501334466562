import React, { useEffect, useMemo, useState } from 'react';
import { count, getAggregateFromServer, getDocs, orderBy, query, sum, where, } from 'firebase/firestore';
import { orderStatus, typeOfOrder, statusLabels, alternativeStatusLabels, paymentPerson } from '../../../../utils/index';
import { Stack, Button, MenuItem, TextField, Tabs, Tab, } from '@mui/material';
import { Title } from '../../../../components/Title/Title';
import { Header } from '../../../../components/Header/Header';
import { useNavigate } from 'react-router-dom';
import { citiesRef, completedOrdersRef, managerRef, orderRef } from '../../../../utils/collectionRefs';
import OrderSort from '../../../../components/OrderSort';
import { fieldsToSearchOrder, headers } from './constants';
import OrderGrid from '../../../../components/OrderGrid ';
import SearchInput from '../../../../components/SearchInput/SearchInput';
import OrderStatistics from '../../../../components/OrderStatistics';
import { Helmet } from 'react-helmet';
import ExportToExcelButton from '../../../../components/Buttons/ExportToExcell';
import { handleCopyOrder } from './helpers/handleCopyOrder';
import { handleChangeStatus } from './helpers/handleChangeStatus';
import { orderColumns } from './helpers/orderColumns';
import './scss/Orders.scss';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';
import { Loader } from '../../../../components/Loader/Loader';

const OrderShetFaktura = ({ pageTitle }) => {
  const [orders, setOrders] = useState(null);
  const [ordersDone, setOrdersDone] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedDate2, setSelectedDate2] = useState('');
  const [managers, setManagers] = useState(null);
  const [cities, setCities] = useState(null);
  const [search, setSearch] = useState('');
  const [aggregates, setAggregates] = useState({})
  const [aggregatesDone, setAggregatesDone] = useState({});
  const [loading, setLoading] = useState(true)
  const [tabIndex, setTabIndex] = useState(0)
  const [selectedFilters, setSelectedFilters] = useState({
    cityTo: '',
    cityFrom: '',
    packageType: '',
    status: '',
    whoPays: '',
  });

  const USERTYPE = localStorage.getItem('userType');
  const navigate = useNavigate();

  const clearFilter = () => window.location.reload();


  useEffect(() => {
    const fetchDocs = async () => {
      setLoading(true);
      const activeOrdersFilter = [];
      const completedOrdersFilter = [];

      Object.keys(selectedFilters).forEach((field) => {
        const value = selectedFilters[field];
        if (value !== 'Все' && value !== '') {
          activeOrdersFilter.push(where(field, '==', value));
          completedOrdersFilter.push(where(field, '==', value));
        }
      });

      if (selectedDate && selectedDate2) {
        const startOfDay = new Date(selectedDate);
        startOfDay.setHours(0, 0, 0, 0);
        const endOfDay = new Date(selectedDate2);
        endOfDay.setHours(23, 59, 59, 999);
        activeOrdersFilter.push(
          where('dateCreated', '>=', startOfDay),
          where('dateCreated', '<=', endOfDay)
        );
        completedOrdersFilter.push(
          where('dateDelivered', '>=', startOfDay),
          where('dateDelivered', '<=', endOfDay)
        );
      }

      activeOrdersFilter.push(orderBy('dateCreated', 'desc'), where('paymentMethod', '==', 'schet_faktura'));
      completedOrdersFilter.push(orderBy('dateDelivered', 'desc'), where('paymentMethod', '==', 'schet_faktura'));

      const queries = [
        query(orderRef, ...activeOrdersFilter),
        query(completedOrdersRef, ...completedOrdersFilter)
      ];

      try {
        const [activeAggregate, completedAggregate] = await Promise.all(
          queries.map(q => getAggregateFromServer(q, {
            count: count(),
            totalCost: sum('cost'),
            totalRedemption: sum('redemption'),
          }))
        );
        setAggregates({ ...activeAggregate.data() });
        setAggregatesDone({ ...completedAggregate.data() });

        const [active, completed] = await Promise.all(
          queries.map(q => getDocs(q))
        );

        const ordersData = active.docs.map(doc => ({ ...doc?.data(), id: doc.id }));
        const ordersDoneData = completed.docs.map(doc => ({ ...doc?.data(), id: doc.id }));

        setOrders(ordersData);
        setOrdersDone(ordersDoneData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching orders:', error);
        setLoading(false);
      }
    };

    fetchDocs();
  }, [selectedFilters, selectedDate, selectedDate2, tabIndex]);
  
  useEffect(() => {
    fetchData(citiesRef, 'name', 'asc', setCities);
    fetchData(managerRef, 'name', 'asc', setManagers);
  }, []);

  const fetchData = async (collectionRef, sortField, sortOrder, setData) => {
    try {
      const querySnapshot = await getDocs(
        query(collectionRef, orderBy(sortField, sortOrder))
      );
      const data = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setData(data);
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  };
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const sortOrderBy = (field, value) => {
    setSelectedFilters((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const createOrderSortHandler = (field) => (event) => {
    sortOrderBy(field, event.target.value);
  }
  const orderCreatorToSort = createOrderSortHandler('creator');
  const orderCityToSort = createOrderSortHandler('cityTo');
  const orderCityFromSort = createOrderSortHandler('cityFrom');
  const orderTypeSort = createOrderSortHandler('packageType');
  const orderStatusSort = createOrderSortHandler('status');
  const orderWhoPaysSort = createOrderSortHandler('whoPays');

  const handleDateChange = (field, value) => {
    if (field === 'selectedDate') {
      setSelectedDate(value);
    } else if (field === 'selectedDate2') {
      setSelectedDate2(value);
    }
  };

  const handleDateChange1 = (e) => handleDateChange('selectedDate', e.target.value);
  const handleDateChange2 = (e) => handleDateChange('selectedDate2', e.target.value);

  const handleStatusChange = async (id, newStatus) => {
    await handleChangeStatus({
      orders: orders,
      id,
      newStatus,
      dataRef: orderRef,
      statusLabels,
      alternativeStatusLabels,
    });
  };

  const handleSearchOrder = (e) => {
    setSearch(e.target.value.toLowerCase().trim());
  };

  const actionsConfig = [
    ...(tabIndex !== 1 ? [{
      title: 'Копировать',
      icon: <FileCopyRoundedIcon style={{ color: '#000b1f' }} />,
      handler: handleCopyOrder,
    }] : []),
    {
      title: 'Редактировать',
      icon: <NoteAltRoundedIcon style={{ color: '#000b1f' }} />,
      handler: (params) => navigate(`/order/update/${params.id}`),
    },
    {
      title: 'История',
      icon: <AccessTimeFilledRoundedIcon style={{ color: '#000b1f' }} />,
      handler: (params) => navigate(`/order/history/orders/${params.id}`),
    },
  ];

  const filteredData = useMemo(() => {
    const ordersToFilter = tabIndex === 0 ? orders : ordersDone;

    if (!ordersToFilter) return { filteredOrders: [], aggregates: { count: 0, totalCost: 0, totalRedemption: 0 } };
    const filteredOrders = ordersToFilter.filter((order) =>
      fieldsToSearchOrder.some((field) => {
        const value = order[field];
        return value && value.toLowerCase().includes(search.toLowerCase());
      })
    );

    const aggregates = {
      count: filteredOrders.length,
      totalCost: filteredOrders.reduce((sum, order) => sum + (order.cost || 0), 0),
      totalRedemption: filteredOrders.reduce((sum, order) => sum + (order.redemption || 0), 0),
    };

    return { filteredOrders, aggregates };
  }, [orders, ordersDone, search, tabIndex]);
  const columns = orderColumns({
    actionsConfig: actionsConfig,
    path: tabIndex === 0 ? '/order/details' : '/order/completed/details',
    orderType: tabIndex === 1 ? 'completed' : '',
  })

  return (
    <>
      <div className='containerr'>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Header previous='Статистика' initial='Список заказов (Счет фактура)' />
        <Title title='Список заказов (Счет фактура)' icon={<AssignmentRoundedIcon fontSize='24' />} />
        <div className='container-inner'>
          <OrderStatistics aggregates={tabIndex === 0 ? aggregates : aggregatesDone} />
          <div className='orders-sorting-header clear-btn'>
            <Button variant='black-contained' onClick={clearFilter}>
              Очистить
            </Button>
            <Stack
              sx={{ width: '100%', flexWrap: 'wrap' }}
              direction='row'
              gap={2}
            >
              <OrderSort
                sortKey='По типу'
                defaultValue=''
                options={typeOfOrder}
                onChange={orderTypeSort}
              />
              <OrderSort
                sortKey='Кто оплачивает'
                defaultValue=''
                options={paymentPerson}
                onChange={orderWhoPaysSort}
              />
              <div className='order-sort'>
                <TextField
                  id='outlined-select-currency'
                  select
                  label='По создателю'
                  fullWidth
                  size='small'
                  defaultValue=''
                  onChange={orderCreatorToSort}
                >
                  <MenuItem value='Все'>Все</MenuItem>
                  {managers?.map((option) => (
                    <MenuItem key={option.login} value={option.login}>
                      {option.login}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className='order-sort'>
                <TextField
                  id='outlined-select-currency'
                  select
                  label='Откуда'
                  fullWidth
                  size='small'
                  defaultValue=''
                  onChange={orderCityFromSort}
                >
                  <MenuItem value='Все'>Все</MenuItem>
                  {cities?.map((option) => (
                    <MenuItem key={option.name} value={parseInt(option.id)}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className='order-sort'>
                <TextField
                  id='outlined-select-currency'
                  select
                  label='Куда'
                  fullWidth
                  size='small'
                  defaultValue=''
                  onChange={orderCityToSort}
                >
                  <MenuItem value='Все'>Все</MenuItem>
                  {cities?.map((option) => (
                    <MenuItem key={option.name} value={parseInt(option.id)}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <OrderSort
                sortKey='По статусу'
                defaultValue=''
                options={orderStatus}
                onChange={orderStatusSort}
              />
              <div className='order-sort order-sort-date'>
                <label htmlFor='orderDate' className='orders-sort-date-label'>
                  От
                  <input
                    className='order-sort-dateInput'
                    type='date'
                    name='orderDate'
                    id='orderDate'
                    value={selectedDate || ''}
                    onChange={handleDateChange1}
                  />
                </label>
                <span></span>
                <label htmlFor='orderDate2' className='orders-sort-date-label'>
                  До
                  <input
                    className='order-sort-dateInput'
                    type='date'
                    name='orderDate2'
                    id='orderDate2'
                    value={selectedDate2 || ''}
                    onChange={handleDateChange2}
                  />
                </label>
              </div>
              {/* <SearchInput
                label='Поиск'
                placeholder='Введите'
                variant='outlined'
                onChange={handleSearchOrder}
              /> */}
            </Stack>
          </div>
          <div className='orders-card-wrapper'>
            <div className='orders-card-header'>
              <div className='orders-card-parent'>
                <div className='orders-card-parent__btns'>
                  {USERTYPE === 'Админ' && (
                    <Button variant='black-contained' onClick={() => navigate('/order/add')}>
                      Новый заказ +
                    </Button>
                  )}
                  <ExportToExcelButton data={orders} headers={headers} fileName="Список заказов" />
                  <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    scrollButtons='auto'
                    variant='scrollable'
                  >
                    <Tab label={`Активные (${aggregates.count})`} />
                    <Tab label={`Завершенные (${aggregatesDone.count})`} />
                  </Tabs>
                </div>
                <div>

                </div>
              </div>
            </div>
            {loading
              ? <Loader />
              : <OrderGrid
                orders={tabIndex === 0 ? orders : ordersDone || []}
                columns={columns}
                size={20}
                handleChangeStatus={handleStatusChange}
              />
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderShetFaktura;