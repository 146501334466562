import React from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import AssignmentTurnedInRoundedIcon from '@mui/icons-material/AssignmentTurnedInRounded';
import MoneyOffCsredRoundedIcon from '@mui/icons-material/MoneyOffCsredRounded';
import AssignmentLateRoundedIcon from '@mui/icons-material/AssignmentLateRounded';
import AssignmentReturnRoundedIcon from '@mui/icons-material/AssignmentReturnRounded';
import UpdateRoundedIcon from '@mui/icons-material/UpdateRounded';
import AutoDeleteRoundedIcon from '@mui/icons-material/AutoDeleteRounded';
import RequestQuoteRoundedIcon from '@mui/icons-material/RequestQuoteRounded';


export const orderLinks = [
  {
    icon: <AddCircleIcon />,
    title: 'Создать заказ',
    path: '/order/add',
  },
  {
    icon: <AssignmentRoundedIcon />,
    title: 'Активные',
    path: '/orders',
  },
  {
    icon: <AssignmentRoundedIcon />,
    title: 'Активные 2',
    path: '/orderss',
  },
  {
    icon: <AssignmentTurnedInRoundedIcon />,
    title: 'Завершеные',
    path: '/order/completed',
  },
  {
    icon: <AssignmentReturnRoundedIcon />,
    title: 'Возвраты',
    path: '/returned-orders',
  },
  {
    icon: <UpdateRoundedIcon />,
    title: 'Долгие',
    path: '/longorders',
  },
  {
    icon: <AssignmentLateRoundedIcon />,
    title: 'Отмененные',
    path: '/order/cancelled',
  },
  {
    icon: <AutoDeleteRoundedIcon />,
    title: 'Удаленные',
    path: '/order/deleted',
  },

  {
    icon: <RequestQuoteRoundedIcon />,
    title: 'Счет фактура',
    path: '/schetFaktura',
  },
  {
    icon: <MoneyOffCsredRoundedIcon />,
    title: 'Долги',
    path: '/order/debt',
  },
]