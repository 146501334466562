import React, { useCallback, useEffect, useState } from 'react'
import { Header } from '../../../../components/Header/Header'
import { Title } from '../../../../components/Title/Title'
import { citiesRef, villageRef } from '../../../../utils/collectionRefs';
import SearchInput from '../../../../components/SearchInput/SearchInput';
import { Tab, Tabs } from '@mui/material';
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import OrderGrid from '../../../../components/OrderGrid ';
import '../Couriers/scss/CourierDetail.scss';
import { Helmet } from 'react-helmet';
import { ExportCitiesToExcellButton } from '../../../../components/Buttons/ExportCitiesToExcell';
import { citiesColumns } from './helpers/CitiesColumns';
import { Loader } from '../../../../components/Loader/Loader';
import { fetchData } from './helpers/fetchData';
import { deleteDoc, doc } from 'firebase/firestore';
import { toast } from 'react-toastify';

const Cities = ({ pageTitle }) => {
  const [cities, setCities] = useState([])
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)

  const loadInitialData = useCallback(async () => {
    setLoading(true);
    const data = await fetchData(citiesRef, 'name', 'asc');
    setCities(data);
    setLoading(false);
  }, []);

  useEffect(() => {
    loadInitialData();
  }, [loadInitialData]);


  const handleSearchOrder = (e) => {
    setSearch(e.target.value.toLowerCase().trim());
  };

  const filteredData = cities.filter((order) =>
    ['name', 'id'].some((field) => {
      const value = order[field];
      return value && value.toLowerCase().includes(search.toLowerCase());
    })
  );

  return (
    <>
      <div className='containerr'>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Header previous='Статистика' initial='Список городов | Регионов' />
        <Title
          title={'Список городов | регионов'}
          icon={<PublicRoundedIcon fontSize='24' />}
        />
        <div className="container-inner">
          <div className="orders-sorting-header">
            <SearchInput
              label='Поиск по городу'
              placeholder='Введите'
              variant='outlined'
              onChange={handleSearchOrder}
            />
          </div>
          <div className='orders-card-wrapper'>
            <div className='orders-card-header'>
              <div className='orders-card-parent'>
                <div className='orders-card-parent__btns'>
                  <ExportCitiesToExcellButton ordersData={cities} fileName='Список городов' />
                </div>
              </div>
            </div>
            {loading
              ? <Loader />
              : <OrderGrid
                orders={filteredData}
                columns={citiesColumns()}
                size={20}
              />}

          </div>
        </div>
      </div>
    </>
  )
}

export default Cities